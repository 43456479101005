<template>
  <div class="form-elements">
    <div class="var-row">
      <div class="flex md12">
        <vuestic-widget>
          <form autocomplete="off">
            <!-- INSERIR NOME -->
            <div class="va-row">
              <div class="flex md12 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-model="user.funcionario.nome"
                        class="simple-input"
                        autocomplete="name"
                        required
                      >
                      <label
                        class="control-label"
                        for="simple-input"
                      >Nome Completo</label>
                      <span
                        class=" alert-danger"
                        v-if="erros['funcionario']"
                      >
                        {{erros['funcionario'].nome[0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- INSERIR CPF -->
              <div class="flex md4 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <the-mask
                        class="simple-input"
                        v-model="user.funcionario.cpf"
                        :mask="['###.###.###-##']"
                        required
                      />
                      <label
                        class="control-label"
                        for="simple-input"
                      >CPF</label>
                      <span
                        class=" alert-danger"
                        v-if="erros['funcionario']"
                      >
                        {{erros['funcionario'].cpf[0]}}</span>

                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- Empresa  -->
              <div class="flex md4 xs12">
                <fieldset>
                  <multiselect
                    v-model="empresasSelecionadas"
                    placeholder="Empresa"
                    track-by="id"
                    label="nome"
                    :options="empresas"
                    :multiple="true"
                  />
                  <span
                    class=" alert-danger"
                    v-if="erros['funcionario']"
                  >
                    {{erros['funcionario'].empresas[0]}}</span>
                </fieldset>
              </div>

              <!-- Area -->
              <div class="flex md4 xs12">
                <fieldset>
                  <multiselect
                    v-model="areasSelecionadas"
                    placeholder="Áreas"
                    track-by="id"
                    label="nome"
                    :options="filtarAreasPorEmpresas()"
                    :multiple="true"
                  >
                    <span slot="noResult">Área não encontrado.</span>
                    <span slot="noOptions">Lista vazia.</span>
                  </multiselect>
                  <span
                    class=" alert-danger"
                    v-if="erros['funcionario']"
                  >
                    {{erros['funcionario'].areas[0]}}</span>
                </fieldset>
              </div>

              <!-- NOME DE USUÁRIO -->
              <div class="flex md4 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-model="user.username"
                        class="simple-input"
                        type="cpf"
                        required
                      >
                      <label
                        class="control-label"
                        for="simple-input"
                      >Nome do usúario</label>
                      <span
                        class="alert-danger"
                        v-if="erros['username']"
                      >
                        {{erros['username'][0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- EMAIL -->
              <div class="flex md8 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-model="user.email"
                        class="simple-input"
                        aria-autocomplete="off"
                        type="email"
                        required
                      >
                      <label
                        class="control-label"
                        for="simple-input"
                      >Email</label>
                      <span
                        class=" alert-warning"
                        v-if="erros['email']"
                      >
                        {{erros['email'][0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- SENHA -->
              <div class="flex md6 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        autocomplete="new-password"
                        :class="senhaValida"
                        v-model="user.password"
                        class="simple-input"
                        type="password"
                        required
                      >
                      <label
                        class="control-label"
                        for="simple-input"
                      >Senha</label>
                      <span
                        class=" alert-danger"
                        v-if="erros['password']"
                      >
                        {{erros['password'][0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- SENHA -->
              <div class="flex md6 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        :class="senhaValida"
                        class="simple-input"
                        type="password"
                        v-model="confirmarSenha"
                        required
                      >
                      <label
                        class="control-label"
                        for="simple-input"
                      >Confirmar senha</label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="butoes">
              <button
                class="btn btn-pale btn-micro"
                @click="$router.push({name: 'lista-funcionarios'})"
              >
                Voltar
              </button>
              <button
                class="btn btn-primary btn-micro"
                @click.prevent.stop="cadastarFuncionario"
                :disabled="camposValidos"
              >
                confirmar
              </button>
            </div>
          </form>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable no-plusplus */
/* eslint-disable radix */
export default {

  name: 'cadastrar-funcionario',
  data() {
    return {
      user: {
        username: '',
        email: '',
        password: '',
        funcionario: {
          nome: '',
          cpf: '',
          areas: [],
          empresas: [],
        },
      },
      areasSelecionadas: [],
      empresasSelecionadas: [],
      empresas: [],
      areas: [],
      erros: {},
      confirmarSenha: '',
    };
  },
  created() {
    this.getEmpresas();
    this.getAreas();
  },
  methods: {
    getEmpresas() {
      this.$axios.get('/api/empresa.json').then((res) => {
        this.empresas = res.data;
      });
    },
    getAreas() {
      this.$axios.get('/api/area.json').then((res) => {
        this.areas = res.data;
      });
    },
    limparCampos() {
      this.erros = {};
      this.confirma = '';
      this.valido = false;
      this.user = {
        username: '',
        email: '',
        password: '',
        funcionario: {
          nome: '',
          cpf: '',
          areas: [],
          empresas: [],
        },
      };
      this.areasSelecionadas = [];
      this.empresasSelecionadas = [];
    },
    pegarIdDosObjetos(objetos) {
      if (objetos.length < 1) return [];
      const arrayDeIds = new Set();
      objetos.forEach(objeto => arrayDeIds.add(objeto.id));
      return arrayDeIds;
    },
    filtarAreasPorEmpresas() {
      if (this.empresasSelecionadas.length < 1) return [];
      const idAreasSelecionadas = new Set();
      this.empresasSelecionadas.forEach((empresa) => {
        empresa.areas.forEach(id => idAreasSelecionadas.add(id));
      });
      const areasPorEmpresas = this.areas.filter(area => idAreasSelecionadas.has(area.id));
      return areasPorEmpresas;
    },
    cadastarFuncionario() {
      this.user.funcionario.empresas = this.pegarIdDosObjetos(this.empresasSelecionadas);
      this.user.funcionario.areas = this.pegarIdDosObjetos(this.areasSelecionadas);
      this.$axios
        .post('/api/funcionario-user.json', this.user)
        .then(() => {
          this.$swal({
            toast: true,
            position: 'top-end',
            showCancelButton: false,
            type: 'success',
            title: 'Cadastrado com Sucesso!',
            showConfirmButton: false,
            timer: 2000,
          });
          this.limparCampos();
        })
        .catch((err) => {
          this.erros = err.response.data;
        });
    },
  },
  computed: {
    camposValidos() {
      const { user } = this;
      return !(this.empresasSelecionadas.length > 0 && this.areasSelecionadas.length > 0
        && user.username && user.email && user.funcionario.nome
        && user.funcionario.cpf && this.senhaValida === 'is_valid');
    },
    senhaValida() {
      let classe = '';
      if (this.confirmarSenha !== this.user.password && this.confirmarSenha !== '') {
        classe = 'not_valid';
      } else if (this.confirmarSenha === this.user.password && this.confirmarSenha !== '') {
        classe = 'is_valid';
      } else {
        classe = '';
      }
      return classe;
    },
  },
};
</script>

<style lang="scss" scoped>
.is_valid {
  color: green !important;
  border-color: green !important;
  &::before {
    content: '';
    background: green !important;
  }
}
.not_valid {
  border-color: red !important;
  &::before {
    content: '';
    background: red !important;
  }
}
.butoes {
  display: flex;
  justify-content: flex-end;
}
.butoes .btn-pale {
  margin-right: 0.8%;
}

.multiselect {
  margin-top: 1%;
}
input {
  height: 41px;
}
@media screen and (max-width: 767px) {
  .multiselect {
    margin-top: 0%;
  }
}
</style>
